import React, {useContext, useEffect, useReducer, useRef, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { MaterialReactTable } from 'material-react-table';
import {Button, Col, Form, Row } from 'react-bootstrap';
import StatusModal from '../StatusModal/StatusModal';
import Switch from '../Switch/Switch';
import useOrgs from '../../custom-hooks/useOrgs';
import { useSelector } from 'react-redux';
import useQuery from '../../custom-hooks/useQuery';
import { ArrowClockwise } from 'react-bootstrap-icons';
import './Status.css';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PM':
      return { ...state, partialMessage: action.payload };
    case 'ADD_PM':
      return {
        ...state,
        partialMessage: [...state.partialMessage, action.payload],
      };
    case 'CLEAR_PM':
      return { ...state, partialMessage: [] };
    case 'SET_ORGS':
      return { ...state, orgs: action.payload };
    case 'ADD_ORGS':
      return { ...state, orgs: [...state.orgs, action.payload] };
    default:
      throw new Error('Unexpected action');
  }
}

const Status = () => {
  const [data, dispatch] = useReducer(reducer, {
    partialMessage: []
  });

  const [organizations] = useOrgs();
  const { partialMessage} = data;
  const isMountedRef = useRef(null);
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const supportLink = "mailto:" + supportEmail;
  const {extUser} = useContext(AuthContext);

  const [showCurrentUserOnly, setShowCurrentUserOnly] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [attFailureFile, setAttFailureFile] = useState('');
  const [deliveryFailureFile, setDeliveryFailureFile] = useState('');
  const [crossFailureFile, setCrossFailureFile] = useState('');
  const [attPartial, setAttPartial] = useState(false);
  const [deliveryPartial, setDeliveryPartial] = useState(false);
  const [crossPartial, setCrossPartial] = useState(false);
  const [submission, setSubmission] = useState({});
  const [cvMessage, setCvMessage] = useState([]);
  const [orgName, setOrgName] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [filesubmissionColumns, setfilesubmissionColumns] = useState([]);
  const [hideSecondaryLoadStatus, sethideSecondaryLoadStatus] = useState(false);
  const idToken = useSelector((state) => state.tokens.idToken);

  const [submissions, getFileSubmissions] = useQuery(showCurrentUserOnly, orgName, setTableLoading, setShowTable);


  useEffect(() => {
    isMountedRef.current = true;
    const publicRegex = /(public).*/i;

    if (attPartial && submission.attFailureFile != null) {
      const attString = submission.attFailureFile.match(publicRegex);
      if (attString) {
        setAttFailureFile(attString[0]);
      }
    }
    if (deliveryPartial && submission.deliveryFailureFile != null) {
      const deliveryString = submission.deliveryFailureFile.match(publicRegex);
      if (deliveryString) {
        setDeliveryFailureFile(deliveryString[0]);
      }
    }
    if (crossPartial && submission.crossFailureFile != null) {
      const crossString = submission.crossFailureFile.match(publicRegex);
      if (crossString) {
        setCrossFailureFile(crossString[0]);
      }
    }

    setfilesubmissionColumns([
      {header: 'Transaction ID', accessorKey: 'transactionId', size: 100},
      {header: 'Username', accessorKey: 'userName', size: 100},
      {header: 'Attribute File Name', accessorKey: 'attFileName', size: 100},
      {header: 'Delivery File Name', accessorKey: 'deliveryFileName', size: 100},
      {header: 'Description', accessorKey: 'description', size: 100},
      {header: 'Organization Name', accessorKey: 'orgName', size: 100},
      {header: 'Status', accessorKey: 'status', size: 100},
      {header: 'Status Message', accessorKey: 'statusMessage', size: 100},
      {header: 'Submission Time', accessorKey: 'frontSubmissionTime', size: 100}
    ]);


    return () => (isMountedRef.current = false);
  }, [
    attPartial,
    deliveryPartial,
    crossPartial,
    submission.attFailureFile,
    submission.deliveryFailureFile,
    submission.crossFailureFile,
  ]);

  const handleAtt = () => {
    getDownloadUrl(attFailureFile, 'attribution');
    setShowModal(true);
  };

  const handleDel = () => {
    getDownloadUrl(deliveryFailureFile, 'delivery');
    setShowModal(true);
  };

  const handleCross = () => {
    getDownloadUrl(crossFailureFile, 'cross');
    setShowModal(true);
  };

  useEffect(() => {
    isMountedRef.current = true;
    attFailureFile && handleAtt();
    deliveryFailureFile && handleDel();
    crossFailureFile && handleCross();
    return () => (isMountedRef.current = false);
  }, [attFailureFile, deliveryFailureFile, crossFailureFile]);

  const closeModal = () => {
    setShowModal(false);
    setAttFailureFile('');
    setDeliveryFailureFile('');
    setCrossFailureFile('');
    setAttPartial(false);
    setDeliveryPartial(false);
    setCrossPartial(false);
    setCvMessage([]);
    dispatch({ type: 'CLEAR_PM' });
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (organizations.length === 1) {
      setOrgName(extUser.attributes['custom:org_name'])
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [getFileSubmissions, organizations, extUser]);

  const getDownloadUrl = async (key, fileType) => {
    const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
    let body = {s3_key: key}
    const download_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/auth/s3/download/url`;
    await axios.post(download_url, body, options)
      .then((res) => {
        getJsonFile(res.data.s3_signed_url, fileType);
      })
      .catch((err) => {
        console.log('This is the error for signed url', err);
      });
  };

  const getJsonFile = async (url, fileType) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        fileType === 'cross' ? setCvMessage([data]) : dispatch({ type: 'ADD_PM', payload: data });
      });
  };

  const myData = submissions.map((sub) => {
    if (sub.originalAction == null) {
      let subClone = Object.assign({}, sub);
      sub.originalAction = subClone.action;
    }
    if (sub.secondaryLoadStatus != null && !hideSecondaryLoadStatus) {

      setfilesubmissionColumns(filesubmissionColumns.toSpliced(7, 0, {header: 'Secondary Load Status', accessorKey: 'secondaryLoadStatus', size: 100}));
      sethideSecondaryLoadStatus(true);
    }
    sub.action = 'partial';
     /* 
      An internal error occurred, please contact{' '} <a href={supportLink}>{supportEmail}</a> and reference transaction ID <strong>{sub.transactionId}</strong>.
      Attribute, Delivery, and Cross Valid files are not valid. Click here for more information
      Attribute and Delivery files are not valid. Click here for more information
      Attribute and Cross Validation files are not valid. Click here for more information
      Cross Validation and Delivery files are not valid. Click here for more information
      Attribute file is not valid. Click here for more information
      Delivery file is not valid. Click here for more information
      Cross Validation file is not valid. Click here for more information
     */
    return {
      userName: sub.userName,
      transactionId: sub.transactionId,
      id: sub.id,
      attFileName: sub.attFileName,
      deliveryFileName: sub.deliveryFileName,
      frontSubmissionTime: sub.frontSubmissionTime,
      orgName: sub.orgName,
      description: sub.description,
      status: sub.status,
      secondaryLoadStatus: sub.secondaryLoadStatus,
      skipAttribution: sub.skipAttribution,
      statusMessage:
        // Internal Error
        sub.internalError === true ? (
          <p>
            An internal error occurred. Please contact the Help Desk at <a href={supportLink}>{supportEmail}</a> for validation errors and reference the transaction ID <strong>{sub.transactionId}</strong>.
          </p>

        // Attribute, Delivery, and Cross Valid files are not valid
        ) : sub.internalError !== true && sub.attValid === false && sub.deliveryValid === false && sub.crossValid === false ? (
            <Button className="table" variant="link" onClick={async () => {
              setAttPartial(true);
              setDeliveryPartial(true);
              setCrossPartial(true);
              setSubmission(sub);
            }}
          >Attribute, Delivery, and Cross Valid files are not valid. Click here for more information</Button>

        // Attribute and Delivery files are not valid
        ) : sub.internalError !== true && sub.attValid === false && sub.deliveryValid === false && sub.crossValid ? (
          <Button className="table" variant="link" onClick={() => {
              setAttPartial(true);
              setDeliveryPartial(true);
              setSubmission(sub);
            }}
          >Attribute and Delivery files are not valid. Click here for more information</Button>

        // Cross Validation & Attribution File Not Valid
        ) : sub.internalError !== true && sub.attValid === false && sub.deliveryValid !== false && sub.crossValid === false ? (
          <Button className="table" variant="link" onClick={() => {
              setAttPartial(true);
              setCrossPartial(true);
              setSubmission(sub);
            }}
          >Attribute and Cross Validation files are not valid. Click here for more information</Button>

        // Cross Validation & Delivery File Not Valid
        ) : sub.internalError !== true && sub.attValid !== false && sub.deliveryValid === false && sub.crossValid === false ? (
          <Button className="table" variant="link" onClick={() => {
              setCrossPartial(true);
              setDeliveryPartial(true);
              setSubmission(sub);
            }}
          >Cross Validation and Delivery files are not valid. Click here for more information</Button>
        
        // Attribution file is not valid
        ) : sub.internalError !== true && sub.attValid === false && sub.deliveryValid !== false && sub.crossValid ? (
          <Button className="table" variant="link" onClick={() => {
              setAttPartial(true);
              setSubmission(sub);
            }}
          >Attribute file is not valid. Click here for more information</Button>

        //Delivery file is not valid
        ) : sub.internalError !== true && sub.attValid !== false && sub.deliveryValid === false && sub.crossValid ? (
            <Button className="table" variant="link" onClick={() => {
              setDeliveryPartial(true);
              setSubmission(sub);
            }}
          >Delivery file is not valid. Click here for more information</Button>

        // Cross Validation not Valid
        ) : sub.internalError !== true && sub.attValid !== false && sub.deliveryValid !== false && sub.crossValid === false ? (
            <Button className="table" variant="link" onClick={() => {
              setCrossPartial(true);
              setSubmission(sub);
            }}
          >Cross Validation file is not valid. Click here for more information</Button>

        // Attribution Incorrect Type / Invalid
        ) : sub.internalError !== true && sub.status === "Incorrect File" && sub.attFailureFile ? (
          <Button className="table" variant="link" onClick={() => {
            setAttPartial(true);
            setSubmission(sub);
          }}
        >Attribution file is not valid. Click here for more information</Button>

        // Delivery Incorrect Type / Invalid
        ) : sub.internalError !== true && sub.status === "Incorrect File" && sub.deliveryFailureFile ? (
          <Button className="table" variant="link" onClick={() => {
            setDeliveryPartial(true);
            setSubmission(sub);
          }}
        >Delivery file is not valid. Click here for more information</Button>

        ) : (
          sub.statusMessage
        ),
    };
  });

 

  const handleToggle = () => {
    setShowCurrentUserOnly(!showCurrentUserOnly);
  };

  return (
    <>
      <div className="container-fluid">
          <Form>
          <Form.Group as={Row} className="mb-3">
          <Col className="switch" sm={2}>
            <Form.Label >
              Show only my uploads:
            </Form.Label>
          </Col>
            <Col className="switch" sm={2}>
            <Switch
              isOn={showCurrentUserOnly}
              onColor="#06D6A0"
              handleToggle={handleToggle}
              switchId="showCurrentUserOnly"
            />
          </Col>

            {organizations.length > 1 && (
              <>
                <Col className="switch" sm={2}>  
                  <Form.Label>
                    Choose Organization:
                  </Form.Label>
                </Col>
                <Col sm={4}> 
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setOrgName(e.target.value);
                    }}
                  >
                    <option>Choose...</option>
                    {organizations.sort().map((org) => {
                      return <option key={`orgs-${org.orgName}`}>{org.orgName}</option>;
                    })}
                  </Form.Control>
                </Col>
              </>
            )}
            <Col sm={2}>
              <ArrowClockwise onClick={getFileSubmissions} style={{ cursor: "pointer" }} />
            </Col>
      
          </Form.Group>
          </Form>
        </div>


        {!showTable && !tableLoading && !showCurrentUserOnly && (
          <div
            style={{
              width: '100%',
              height: 80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '18px',
              color: '#223c89',
            }}
          >
            Select an organization from the dropdown or turn on "Show only my uploads" to filter submissions.
          </div>
        )}

        {tableLoading && !showTable && (
          <div
            style={{
              width: '100%',
              height: 80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ThreeDots
              color="#223c89"
              height="80"
              width="80"
            />
          </div>
        )}

        <div >
          {showTable && (
            <MaterialReactTable 
              data={myData}
              columns={filesubmissionColumns}
              muiTableHeadRowProps={{
                sx: { backgroundColor: '#223c89' }
              }}
              muiTableHeadCellProps={{
                sx: { color: '#FFFFFF', overflowWrap: 'break-word', whiteSpace: 'normal', display: 'inline-block'}
              }}
              muiTableBodyCellProps={{
                sx: { overflowWrap: 'break-word', whiteSpace: 'normal', display: 'inline-block' }
              }}
              sx={{
                '& .css-lapokc': {
                  whiteSpace: 'normal'
                }   
              }}
              caption="File Submission Status"
              enableColumnResizing={true}
              enableColumnActions={false}
              positionPagination='both'
              layoutMode="grid"
              initialState={{
                sorting: [{id: 'frontSubmissionTime', desc: true}]
              }}
            />
          )}
        </div>

      <div>
        <StatusModal
          partialMessage={partialMessage}
          submission={submission}
          showModal={showModal}
          closeModal={closeModal}
          cvMessage={cvMessage}
        />
      </div>
    </>

  );
};

export default Status;
