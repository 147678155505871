import React, { useEffect, useReducer, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Button } from 'react-bootstrap';
import useOrgs from '../../custom-hooks/useOrgs';
import AddOrgModal from '../OrgModals/AddOrgModal';
import DeleteOrgModal from '../OrgModals/DeleteOrgModal';
import EditOrgModal from '../OrgModals/EditOrgModal';
import DupeOrgsModal from '../OrgModals/DupeOrgsModal';
import BulkAddOrgModal from '../OrgModals/BulkAddOrgModal';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './Organization.css';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: action.duplicateOrgs,
      };
    case 'ADD_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: [...state.duplicateOrgs, action.duplicateOrgs],
      };
    case 'SET_ORG_TYPE':
      return {
        ...state,
        orgType: action.orgType,
      };
    case 'RESET_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: [],
        orgType: '',
        showDupeOrgsModal: false,
      };
    case 'SHOW_DUPE_ORGS_MODAL':
      return {
        ...state,
        showDupeOrgsModal: true,
      };
    case 'ADD_INVALID_ORG_TYPE':
      return {
        ...state,
        invalidOrgTypes: action.invalidOrgTypes,
        errorMessage: action.errorMessage,
        showErrorModal: action.errorMessage,
        errorType: action.errorType,
      };

    default:
      throw new Error('Action not found');
  }
};

const Organization = () => {
  const isMountedRef = useRef(null);

  const [data, dispatch] = useReducer(reducer, {
    duplicateOrgs: [],
    orgType: '',
    showDupeOrgsModal: false,
    invalidOrgTypes: null,
    errorMessage: '',
    showErrorModal: false,
    errorType: '',
  });

  const { duplicateOrgs, invalidOrgTypes, showDupeOrgsModal } = data;

  const [tableLoading, setTableLoading] = useState(true);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showBulkAddOrgModal, setShowBulkAddOrgModal] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState('');
  const [orgNameToDelete, setOrgNameToDelete] = useState('');
  const [orgToEdit, setOrgToEdit] = useState('');
  const [orgToEditName, setOrgToEditName] = useState('');
  const [orgToEditPopulation, setOrgToEditPopulation] = useState('');
  const [orgToEditType, setOrgToEditType] = useState('');
  const [specificationType, setSpecificationType] = useState('');
  const [independentFileLoad, setIndependentFileLoad] = useState(false);
  const [expireACRS, setExpireACRS] = useState(false);
  const [expirationDays, setExpirationDays] = useState('90');

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const [organizations, getOrganizations, clearOrganizations] = useOrgs();
  const idToken = useSelector((state) => state.tokens.idToken);

  useEffect(() => {
    isMountedRef.current = true;
    
    organizations.length !== 0 && setTableLoading(false);
    return () => (isMountedRef.current = false);
  }, [tableLoading, organizations]);

  useEffect(() => {
    isMountedRef.current = true;
    duplicateOrgs.length > 0 && dispatch({ type: 'SHOW_DUPE_ORGS_MODAL' });
    return () => (isMountedRef.current = false);
  }, [duplicateOrgs]);

  const handleEditOrgModal = (org) => {
    setOrgToEdit(org.orgName);
    setOrgToEditName(org.orgName);
    
    if (org.orgType) {
      setOrgToEditType(org.orgType);
    }
    else {
      setOrgToEditType('');
    }

    if (org.orgPopulation) {
      setOrgToEditPopulation(org.orgPopulation);
    }
    else {
      setOrgToEditPopulation('');
    }

    if (org.specificationType) {
      setSpecificationType(org.specificationType);
    }
    else {
      setSpecificationType('');
    }
    if (org.independentFileLoad) {
      setIndependentFileLoad(org.independentFileLoad);
    }
    else {
      setIndependentFileLoad(false);
    }
    if (org.expireACRS) {
      setExpireACRS(org.expireACRS);
    }
    else {
      setExpireACRS(false);
    }

    if (org.expirationDays) {
      setExpirationDays(org.expirationDays);
    }
    else {
      setExpirationDays('90');
    }
    setShowEditOrgModal(true);
  };

  const handleDeleteOrgModal = (org) => {
    setOrgToDelete(org.orgName);
    setOrgNameToDelete(org.orgName);
    setShowConfirmationModal(true);
  };

  const handleAddOrgModal = () => {
    setShowAddOrgModal(true);
  };

  const handleBulkAddOrgModal = () => {
    setShowBulkAddOrgModal(true);
  };

  const closeModal = () => {
    if (showAddOrgModal) {
      setShowAddOrgModal(false);
    }
    if (showDeleteOrgModal) {
      setShowDeleteOrgModal(false);
    }
    if (showEditOrgModal) {
      setShowEditOrgModal(false);
    }
    if (showBulkAddOrgModal) {
      setShowBulkAddOrgModal(false);
    }
  };

  const handleCloseDupeOrgsModal = () => {
    dispatch({ type: 'RESET_DUPLICATE_ORGS' });
  };

  const handleDelete = async (e) => {
    setIsDeleting(true);
    e.preventDefault();

    try {
      const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organization?orgName=${orgToDelete}`;
      const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
      await axios.delete(organizations_url, options)
      .then((res) => {

      })
      .catch((err) => {
        console.error(err);
      });
    } catch (err) {
      console.error(err, err.stack);
    }
    getOrganizations();
    setShowConfirmationModal(false);
    setIsDeleting(false);
  };

  const handleCancel = () => {
    setIsCancelling(true);
    setShowConfirmationModal(false);
    setIsCancelling(false);
  };

  const handleRefresh = () => {
    clearOrganizations();
    getOrganizations();
  };

  const myData = organizations.map((sub) => {
    return {
      orgName: sub.orgName,
      orgPopulation: sub.orgPopulation,
      orgType: sub.orgType,
      specificationType: sub.specificationType,
      independentFileLoad: sub.independentFileLoad,
      expireACRS: sub.expireACRS,
      expirationDays: sub.expirationDays,
      edit: (
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            handleEditOrgModal(sub);
          }}
        >
          Edit/View
        </Button>
      ),
      delete: (
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            handleDeleteOrgModal(sub);
          }}
        >
          Delete
        </Button>
      ),
    };
  });

  return (
    <>
      <div>
        <div className="Organization mt-2 d-inline-block">
          <Button onClick={handleAddOrgModal} variant="link">
            <i className="material-icons icon mr-1">add_circle_outline</i>

            <span>Add Organization</span>
          </Button>
        </div>
        <div className="Organization mt-2 d-inline-block">
          <Button onClick={handleBulkAddOrgModal} variant="link">
            <i className="material-icons icon mr-1">add_circle_outline</i>

            <span>Bulk Add Organization</span>
          </Button>
        </div>
        <div className="Organization mt-2 d-inline-block">
          <Button onClick={handleRefresh} variant="link">
            <i className="material-icons icon mr-1">refresh</i>

            <span>Refresh Table</span>
          </Button>
        </div>

        <MaterialReactTable 
          data={myData}
          columns={[
            { header: 'Organization Name', accessorKey: 'orgName', size: 100},
            { header: 'Population', accessorKey: 'orgPopulation', size: 100},
            { header: 'Specification Type', accessorKey: 'specificationType', size: 100},
            { header: 'Organization Type', accessorKey: 'orgType', size: 100},
            { header: 'Expiration Days', accessorKey: 'expirationDays', size: 100},
            { header: 'Expire ACRS on Load', accessorKey: 'expireACRS', size: 100, Cell: ({ cell }) => { return formatBoolColumn(cell.getValue()); } },
            { header: 'Independently Load Attribution or Delivery Files', accessorKey: 'independentFileLoad', size: 100, Cell: ({ cell }) => { return formatBoolColumn(cell.getValue()); }  },
            { header: '', accessorKey: 'edit', size: 100, enableSorting: false },
            { header: '', accessorKey: 'delete', size: 100, enableSorting: false },
            ]}
          muiTableHeadRowProps={{
            sx: { backgroundColor: '#223c89' }
          }}
          muiTableHeadCellProps={{
            sx: { color: '#FFFFFF', overflowWrap: 'break-word', whiteSpace: 'normal', display: 'inline-block'}
          }}
          muiTableBodyCellProps={{
            sx: { overflowWrap: 'break-word', whiteSpace: 'normal', display: 'inline-block' }
          }}
          sx={{
            '& .css-lapokc': {
              whiteSpace: 'normal'
            }   
          }}
          caption="Manage Organizations"
          enableColumnResizing={true}
          enableColumnActions={false}
          positionPagination='both'
          layoutMode="grid"
          initialState={{
            sorting: [{id: 'orgName', desc: false}]
          }}
        />
      </div>
      <div>
        <AddOrgModal
          showAddOrgModal={showAddOrgModal}
          closeModal={closeModal}
          dispatch={dispatch}
          handleRefresh={handleRefresh}
          organizations={organizations}
        />
      </div>
      <div>
        <BulkAddOrgModal
          showBulkAddOrgModal={showBulkAddOrgModal}
          closeModal={closeModal}
          dispatch={dispatch}
          handleRefresh={handleRefresh}
          organizations={organizations}
        />
      </div>
      <div>
        <ConfirmationModal
          id={orgToDelete}
          showModal={showConfirmationModal}
          hideModal={() => setShowConfirmationModal(false)}
          action="Delete"
          item={orgNameToDelete}
          type="organization"
          handleConfirm={handleDelete}
          handleCancel={handleCancel}
          isConfirming={isDeleting}
          isCancelling={isCancelling}
          confirmBtnBgColor="#f44336"
          confirmBtnColor="#fff"
          cancelBtnBgColor="#ccc"
          cancelBtnColor="#fff"
        />
      </div>
      <div>
        <DeleteOrgModal
          orgToDelete={orgToDelete}
          orgNameToDelete={orgNameToDelete}
          showDeleteOrgModal={showDeleteOrgModal}
          closeModal={closeModal}
          handleRefresh={handleRefresh}
        />
      </div>
      <div>
        <EditOrgModal
          orgToEdit={orgToEdit}
          orgToEditName={orgToEditName}
          orgToEditPopulation={orgToEditPopulation}
          orgToEditType={orgToEditType}
          specificationType={specificationType}
          independentFileLoad={independentFileLoad}
          expireACRS={expireACRS}
          expirationDays={expirationDays}
          showEditOrgModal={showEditOrgModal}
          handleRefresh={handleRefresh}
          closeModal={closeModal}
        />
      </div>
      <div>
        <DupeOrgsModal
          closeModal={handleCloseDupeOrgsModal}
          showDupeOrgsModal={showDupeOrgsModal}
          dupeOrgs={duplicateOrgs}
          invalidOrgTypes={invalidOrgTypes}
        />
      </div>
    </>
  );
};

function formatBoolColumn(value) {
  let result = ""
  if (value != null) {
    result = value.toString();
  }
  return result;
}

export default Organization;
